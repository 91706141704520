<template>
  <div>
    <p style="font-family: Helvetica, Arial, sans-serif; opacity: 1;text-anchor: start;
    dominant-baseline: auto;
    font-size: 14px;
    font-weight: 900;
    fill: rgb(55, 61, 63); margin-left: 15px">今年月销售额对比</p>
    <div id="chart">
      <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import * as dayjs from "dayjs";
import * as priceAdminService from "@/api/admin/price";

let quarterOfYear = require('dayjs/plugin/quarterOfYear')
dayjs.extend(quarterOfYear)

export default {
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          height: 380,
          type: 'line',
          stacked: false,
          toolbar: {
            show: false,
          }
        },
        stroke: {
          width: [0, 2, 5],
          curve: 'smooth'
        },
        plotOptions: {
          bar: {
            columnWidth: '50%'
          }
        },

        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100]
          }
        },
        labels: ['一月', '二月', '三月', '四月', '五月', '六月', '七月',
          '八月', '九月', '十月', '十一月', '十二月'
        ],
        markers: {
          size: 0
        },
        xaxis: {
          type: 'category'
        },
        yaxis: {
          min: 0
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return y.toFixed(0) + " 元";
              }
              return y;

            }
          }
        }
      },
    }
  },
  created() {
    priceAdminService.saleLineColumnAreaMonth().then(res => {
      if (res.status === 200) {
        console.log('saleLineColumnAreaMonth', res.data)
        this.series = res.data
      }
    })
  },
  mounted() {
  },
  methods: {
    toHome() {
      let d = this.$refs.chart
      let a = d.scrollWidth
      d.parentElement.scrollTo(a, 0)
    },

  }
}
</script>
<style lang="less">

.line-div::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.line-div {
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
</style>
