<template>
  <div>
    <div class="line-div" style="width: 100%; height: 505px;
    overflow: auto; margin-bottom: 0px;">
      <p style="font-family: Helvetica, Arial, sans-serif; opacity: 1;text-anchor: start;
    dominant-baseline: auto;
    font-size: 14px;
    font-weight: 900;
    fill: rgb(55, 61, 63); margin-left: 15px">过去12个月每月销售额对比</p>
      <van-button type="primary" size="small" :loading="addLoading" icon="plus" style="margin-left: 15px" @click="getSaleLineData('add')"></van-button>
      <van-button type="primary" size="small" :loading="subLoading" icon="minus" style="margin-left: 5px" @click="getSaleLineData('sub')"></van-button>
      <div class="chart" id="chart" ref="chart">
        <apexchart type="line" height="400" width="1200" :options="chartOptions" :series="series"></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import * as priceAdminService from "@/api/admin/price";

export default {
  components: {
    apexchart: VueApexCharts
  },
  data() {

    return {
      series: [],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          toolbar: {
            show: false,
            autoSelected: 'pan'
          },
          zoom: {
            enabled: false
          },
          animations: {
            enabled: false
          }
        },
        stroke: {
          width: [5,5,4],
          curve: 'straight'
        },
        labels: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10'
          , '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'
          , '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],

        // title: {
        //   text: '过去12个月每月销售额对比'
        // },
        xaxis: {
        },
      },
      beforeMonth: 3,
      addLoading: false,
      subLoading: false,
    }
  },
  created() {
    let $this = this
    priceAdminService.getSaleLine(this.beforeMonth).then(res => {
      if (res.status === 200) {
        this.series = res.data
        console.log("333")
        // let d = $this.$refs.chart
        let d = document.querySelector(".chart")
        console.log(d)
        let a = d.scrollWidth
        console.log("333 scrollWidth")
        console.log(a)
        d.parentElement.scrollTo(a, 0)
      }
    })
  },
  mounted() {
    // console.log("111")
    // let d = this.$refs.chart
    // // let d = document.querySelector(".chart")
    // console.log(d)
    // let a = d.scrollWidth
    // console.log(a)
    // d.parentElement.scrollTo(a, 0)
  },
  methods: {
    getSaleLineData(type) {
      if (type === 'add') {
        this.addLoading = true
        this.beforeMonth ++
      } else {
        this.subLoading = true
        this.beforeMonth --
      }
      if (this.beforeMonth > 12) {
        this.beforeMonth = 12
      }
      if (this.beforeMonth < 1) {
        this.beforeMonth = 1
      }
      priceAdminService.getSaleLine(this.beforeMonth).then(res => {
        if (res.status === 200) {
          this.series = res.data
          this.subLoading = false
          this.addLoading = false
        }
      })
    },
    toHome() {
      // let d = this.$refs.chart
      let d = document.querySelector(".chart")
      console.log(d)
      let a = d.scrollWidth
      console.log(a)
      d.parentElement.scrollTo(a, 0)
      // this.$nextTick(() => {
      //   let d = document.querySelector(".chart")
      //   console.log(d)
      //   let a = d.offsetTop
      //   console.log(a)
      //   d.parentElement.scrollTo(0, a)
      // })
      // this.$router.push({path: '/index'})
    },

  }
}
</script>
<style lang="less">

.line-div::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.line-div {
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
</style>
