<template>
  <div>
      <p style="font-family: Helvetica, Arial, sans-serif; opacity: 1;text-anchor: start;
    dominant-baseline: auto;
    font-size: 14px;
    font-weight: 900;
    fill: rgb(55, 61, 63); margin-left: 15px">近五年月销售额对比</p>
      <div class="chart" id="chart" ref="chart">
        <apexchart type="line" height="400" :options="chartOptions" :series="series"></apexchart>
      </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import * as priceAdminService from "@/api/admin/price";

export default {
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          toolbar: {
            show: false,
            autoSelected: 'pan'
          },
          zoom: {
            enabled: false
          },
          animations: {
            enabled: false
          }
        },
        stroke: {
          width: [5,5,4],
          curve: 'straight'
        },
        labels: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        // title: {
        //   text: '过去12个月每月销售额对比'
        // },
        xaxis: {
        },
      },
      beforeMonth: 5,
      addLoading: false,
      subLoading: false,
    }
  },
  created() {
    priceAdminService.getSaleLineMonth(this.beforeMonth).then(res => {
      if (res.status === 200) {
        this.series = res.data
        let d = document.querySelector(".chart")
        let a = d.scrollWidth
        d.parentElement.scrollTo(a, 0)
      }
    })
  },
  mounted() {
  },
  methods: {
    toHome() {
      let d = document.querySelector(".chart")
      let a = d.scrollWidth
      d.parentElement.scrollTo(a, 0)
    },
  }
}
</script>
<style lang="less">

.line-div::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.line-div {
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
</style>
